import React, { FC } from 'react';
import styled from 'styled-components';
import { ContentfulMovie } from '../model/generated/graphql';
import { getImage } from 'gatsby-plugin-image';
import { motion, useCycle } from 'framer-motion';
import { Overlay } from './Layout/Layout';
import { Link } from 'gatsby';
import { Locale } from '../modules/movies/components/MovieNav';
import { pxToRem } from '../utils/pxToRem';
import { BgImage } from 'gbimage-bridge';

type Props = {
    movies: ContentfulMovie[];
    activeLocale: Locale;
};

const variants = {
    open: {
        y: '69vh',
        // x: '-50%',
        transition: {
            y: { type: 'spring', damping: 17 },
        },
    },
    closed: {
        y: 0,
        // x: '-50%',
        transition: {
            y: { type: 'spring', damping: 17 },
        },
    },
    hover: {
        y: 20,
        transition: {
            y: { type: 'spring', damping: 17 },
        },
    },
};

const overlay = {
    open: {
        display: 'block',
        opacity: 0.5,
        transition: {
            type: 'spring',
            stiffness: 20,
            restDelta: 2,
        },
    },
    closed: {
        opacity: 0,
        transition: {
            delay: 0.2,
            type: 'spring',
            stiffness: 400,
            damping: 40,
        },
        transitionEnd: {
            display: 'none',
        },
    },
};

const gradient = {
    open: { opacity: 1, transition: { duration: 0.1 } },
    closed: { opacity: 0, transition: { duration: 1 } },
};

const imgTransition = {
    type: 'tween',
    duration: 2.4,
    ease: [0.51, 0, 0, 0.99],
};

export const Notch: FC<Props> = ({ movies, activeLocale }) => {
    const [isOpen, toggleOpen] = useCycle(false, true);

    return (
        <>
            <Wrapper
                variants={variants}
                initial="closed"
                animate={isOpen ? 'open' : 'closed'}
                key="notch"
                whileHover={!isOpen ? 'hover' : 'open'}
            >
                <ContentWrapper>
                    <List>
                        {movies.map((el) => {
                            const image = getImage(el.cover?.gatsbyImageData);
                            const fallback = getImage(movies[0].cover.gatsbyImageData);

                            return (
                                <ListItem key={el.id}>
                                    <StyledLink
                                        to={`/${activeLocale === 'en' ? 'en' : 'pl'}/projects/${el.slug}`}
                                        onClick={() => toggleOpen()}
                                    >
                                        <ImgWrapper whileHover={{ scale: 0.95 }} transition={imgTransition}>
                                            <ImgInner whileHover={{ scale: 1.5 }} transition={imgTransition}>
                                                <BackgroundImage image={image ? image : fallback} />
                                            </ImgInner>
                                        </ImgWrapper>
                                        <Title>
                                            {el.title}
                                            {el.movieInfo && (
                                                <>
                                                    <ReleaseDate>
                                                        {'  '}
                                                        {el.movieInfo.releaseDate && (
                                                            <>
                                                                <TitleDivider> || </TitleDivider>
                                                                {el.movieInfo.releaseDate}
                                                            </>
                                                        )}
                                                    </ReleaseDate>
                                                    {(el.status === 'in development' ||
                                                        el.status === 'in production' ||
                                                        el.status === 'w developmencie' ||
                                                        el.status === 'w produkcji') && (
                                                        <>
                                                            <TitleDivider> || </TitleDivider>
                                                            <MovieStatus> {el.status}</MovieStatus>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Title>
                                    </StyledLink>
                                </ListItem>
                            );
                        })}
                    </List>
                    <Gradient variants={gradient} />
                </ContentWrapper>
                <StyledNotch onClick={() => toggleOpen()}>OTHER PROJECTS</StyledNotch>
            </Wrapper>
            <Overlay
                initial={'closed'}
                variants={overlay}
                animate={isOpen ? 'open' : 'closed'}
                onClick={() => isOpen && toggleOpen()}
            />
        </>
    );
};

const borderRadius = '25px';
const nothchBg = '#EEEEEE';

const TitleDivider = styled.span`
    font-size: 1.5rem;
    padding: 0 ${pxToRem(10)};
`;

const MovieStatus = styled.span`
    background-color: #ff5722;
    margin-left: ${pxToRem(5)};
    padding: ${pxToRem(2)} ${pxToRem(8)};
    font-size: 0.8rem;
    border-radius: ${pxToRem(4)};
`;

const Gradient = styled(motion.div)`
    position: fixed;
    bottom: ${pxToRem(-2)};
    left: 0;
    right: 0;
    height: ${pxToRem(70)};
    z-index: 9999;
    pointer-events: none;
    border-bottom-left-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};
    background: linear-gradient(
        0deg,
        rgba(238, 238, 238, 1) 0%,
        rgba(238, 238, 238, 1) 50%,
        rgba(238, 238, 238, 0.008862920168067223) 100%
    );
`;

const Title = styled.p`
    position: absolute;
    bottom: ${pxToRem(25)};
    left: ${pxToRem(25)};
    color: var(--color-text-primary);
    display: block;
    text-align: left;
    line-height: 1;
    margin: 0;
    text-shadow: 0px 0px ${pxToRem(5)} #666;
    font-size: 1.5rem;
    pointer-events: none;
`;

const ReleaseDate = styled.span`
    font-size: 1rem;
`;

const HoverTitle = styled.p`
    transition: opacity 200ms ease-in-out;
    opacity: 0;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.6);
`;

const StyledLink = styled(motion(Link))`
    overflow: hidden;
`;

const StyledNotch = styled(motion.button)`
    position: absolute;
    bottom: ${pxToRem(-32)};
    left: 0;
    right: 0;
    margin: auto;
    width: ${pxToRem(200)};
    background-color: ${nothchBg};
    border-bottom-left-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};
    border-width: 0;
    outline: none;
    cursor: pointer;
    z-index: -1;
    padding: ${pxToRem(29)} 0 ${pxToRem(8)};
    &:hover {
        text-shadow: ${pxToRem(2)} ${pxToRem(2)} #fff;
    }
`;

const Wrapper = styled(motion.div)`
    position: absolute;
    height: 70vh;
    background-color: ${nothchBg};
    top: -70vh;
    padding: ${pxToRem(25)};
    box-sizing: border-box;
    z-index: 20;
    box-shadow: 0px ${pxToRem(10)} ${pxToRem(100)} rgba(0, 0, 0, 1);
    border-bottom-left-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};
    @media (max-width: ${pxToRem(600)}) {
        padding: ${pxToRem(0)};
    }
`;
const ContentWrapper = styled.div`
    position: relative;
    height: 100%;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;
const List = styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    column-gap: ${pxToRem(25)};
    row-gap: ${pxToRem(25)};
    justify-content: center;
    padding: 0;
    box-sizing: border-box;
    margin-bottom: ${pxToRem(50)};
`;

const ListItem = styled.li`
    position: relative;
`;

const ImgWrapper = styled(motion.div)`
    overflow: hidden;
    border-radius: ${pxToRem(8)};
`;

const ImgInner = styled(motion.div)`
    position: relative;
    overflow: hidden;
    border-radius: ${pxToRem(8)};
    width: ${pxToRem(600)};
    height: ${pxToRem(280)};
    @media (max-width: ${pxToRem(1900)}) {
        width: ${pxToRem(500)};
        height: ${pxToRem(200)};
    }
    @media (max-width: ${pxToRem(1600)}) {
        max-width: ${pxToRem(400)};
        max-height: ${pxToRem(170)};
    }

    @media (max-width: ${pxToRem(600)}) {
        max-width: ${pxToRem(300)};
        max-height: ${pxToRem(170)};
    }
`;

const BackgroundImage = styled(BgImage)`
    width: 100%;
    height: 100%;
    border-radius: ${borderRadius};
    display: block !important;
`;
