import React, { FC } from 'react';
import { motion, Variants } from 'framer-motion';
import LocaleLink from './LocaleLink/LocaleLink';
import Return from '../../static/icons/left-arrow-point.svg';
import styled from 'styled-components';
import { materialTransition } from '../utils/transitions';
import { pxToRem } from '../utils/pxToRem';

type Props = {
    to: string;
};

const backVariants: Variants = {
    exit: { x: 100, opacity: 0, transition: materialTransition },
    enter: { x: 0, opacity: 1, transition: { delay: 1, ...materialTransition } },
};

export const BackLink: FC<Props> = ({ to }) => {
    return (
        <Wrapper>
            <LocaleLink to={to}>
                <StyledBackLink variants={backVariants}>
                    <motion.div
                        whileHover={{
                            x: -20,
                            transition: { ...materialTransition, duration: 0.8, delay: 0.1 },
                        }}
                    >
                        <Return width={50} height={50} fill={'#fff'} />
                    </motion.div>
                </StyledBackLink>
            </LocaleLink>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: fixed;
    width: ${pxToRem(70)};
    height: ${pxToRem(70)};
    top: ${pxToRem(24)};
    right: ${pxToRem(0)};
    box-sizing: border-box;
    z-index: 10;
`;

const StyledBackLink = styled(motion.div)``;

const Link = styled(LocaleLink)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;
