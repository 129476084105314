import React, { FC, useContext } from 'react';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { motion, Variants } from 'framer-motion';
import styled from 'styled-components';

import { ContentfulAsset, ContentfulCategoryMoviesConnection, ContentfulMovie } from '../model/generated/graphql';
import { Gallery } from '../modules/movies/components/Gallery';
import { pxToRem } from '../utils/pxToRem';
import { LocaleSwitcher } from '../components/LocaleSwitcher/LocaleSwitcher';
import { BackLink } from '../components/BackLink';
import { BackgroundImage } from '../modules/movies/components/Section';
import { Video } from '../modules/movies/components/Video';
import { Info } from '../modules/movies/components/Info';
import { Notch } from '../components/Notch';
import LocaleContext from '../context/Locale';
import { ProjectSectionTitle } from '../modules/movies/components/styles';

type MovieQueryProps = {
    contentfulMovie: ContentfulMovie & { galleryMain: ContentfulAsset[]; galleryThumb: ContentfulAsset[] };
    allContentfulCategoryMovies: ContentfulCategoryMoviesConnection;
};
type MoviePageProps = PageProps<MovieQueryProps>;

const transition = {
    duration: 1,
    ease: [0.43, 0.13, 0.23, 0.96],
};

export const imageVariants: Variants = {
    exit: { y: '50%', opacity: 0, transition },
    enter: {
        y: '0%',
        opacity: 1,
        transition,
    },
};

export const ProjectDetails: FC<MoviePageProps> = ({ data }) => {
    const { title, description, cover2, movieInfo, vimeoMovieId, galleryMain, galleryThumb } = data.contentfulMovie;
    const image = getImage(cover2?.gatsbyImageData);
    const { activeLocale } = useContext(LocaleContext);

    return (
        <>
            <Notch movies={data.allContentfulCategoryMovies.nodes[0].movies} activeLocale={activeLocale} />
            <motion.div initial="exit" animate="enter" exit="exit">
                <motion.div key={data.contentfulMovie.title} variants={imageVariants}>
                    <Component>
                        <MobileCoverImage image={image} alt={''} />
                        <BackgroundImage image={image}>
                            <ContentWrapper>
                                <IntroContent>
                                    <div>
                                        <ProjectSectionTitle>{title}</ProjectSectionTitle>
                                        {description?.childMarkdownRemark?.html && (
                                            <Description
                                                dangerouslySetInnerHTML={{
                                                    __html: description?.childMarkdownRemark?.html,
                                                }}
                                            />
                                        )}
                                    </div>
                                </IntroContent>
                            </ContentWrapper>
                        </BackgroundImage>
                    </Component>
                </motion.div>
                <BackLink to={`/projects/${data.contentfulMovie.slug}`} />
                {/*<LocaleSwitcher />*/}

                {galleryMain && <Gallery thumbs={galleryThumb} images={galleryMain} />}

                {vimeoMovieId && <Video videoId={vimeoMovieId} />}

                {movieInfo && <Info data={movieInfo} />}
            </motion.div>
        </>
    );
};

export default ProjectDetails;

const ContentWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const MobileCoverImage = styled(GatsbyImage)`
    display: none;
    @media (max-width: ${pxToRem(859)}) {
        display: block;
    }
`;

const Component = styled.div`
    display: flex;
    justify-content: end;
    @media (max-width: ${pxToRem(859)}) {
        flex-direction: column;
    }
`;

const IntroContent = styled.div`
    background-color: var(--background-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 53%;
    min-height: calc(100vh - 100px);
    padding: 50px;
    @media (max-width: ${pxToRem(860)}) {
        padding: 50px 12px;
    }
    @media (max-width: ${pxToRem(860)}) {
        width: 100%;
        min-height: 80%;
    }
`;

export const Description = styled.div`
    max-width: 600px;
    color: #fff;
    font-size: 1.5rem;
    line-height: 1.5;
    @media (max-width: ${pxToRem(1440)}) {
        font-size: 1.2rem;
    }
`;

export const pageQuery = graphql`
    query MovieExploreBySlug($node_locale: String, $slug: String) {
        contentfulMovie(node_locale: { eq: $node_locale }, slug: { eq: $slug }) {
            id
            title
            slug
            description {
                childMarkdownRemark {
                    html
                }
            }
            cover {
                gatsbyImageData(width: 1920, height: 1080, quality: 90, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            cover2 {
                gatsbyImageData(width: 1920, height: 1070, quality: 90, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            galleryMain: gallery {
                id
                gatsbyImageData(layout: CONSTRAINED, quality: 90, placeholder: BLURRED)
            }
            galleryThumb: gallery {
                id
                gatsbyImageData(
                    layout: FIXED
                    width: 200
                    height: 200
                    cropFocus: CENTER
                    quality: 90
                    placeholder: BLURRED
                )
            }
            vimeoMovieId
            movieInfo {
                director
                scriptwriter
                artisticProject
                animations
                cinematography
                editing
                music
                techique
                production
                coProducers
                coFinancing
                releaseDate
            }
        }
        allContentfulCategoryMovies(filter: { node_locale: { eq: $node_locale } }) {
            nodes {
                id
                title
                movies {
                    id
                    title
                    slug
                    movieInfo {
                        releaseDate
                    }
                    status
                    cover {
                        gatsbyImageData(
                            layout: FIXED
                            width: 600
                            height: 280
                            cropFocus: CENTER
                            quality: 90
                            formats: [AUTO, WEBP]
                            placeholder: BLURRED
                        )
                    }
                }
            }
        }
    }
`;
