import React, { FC, useState } from 'react';
import NoSound from '../../../../static/icons/volume_off_black_24dp.svg';
import Sound from '../../../../static/icons/volume_up_black_24dp.svg';
import Vimeo from '@u-wave/react-vimeo';
import styled from 'styled-components';
import { pxToRem } from '../../../utils/pxToRem';
import { motion, useTransform, useViewportScroll } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { imageVariants } from '../../../templates/projectDetails';
import VimeoLogo from '../../../../static/icons/vimeo-logo.svg';

interface Props {
    videoId: string;
}

const VIMEO_URL = 'https://vimeo.com/';
const VIMEO_PLAYER_URL = 'https://player.vimeo.com/video/';

export const Video: FC<Props> = ({ videoId }) => {
    const [volume, setVolume] = useState(0);
    const innerHeight = typeof window !== 'undefined' ? 2 * window.innerHeight - 180 : 2000;
    const { scrollY } = useViewportScroll();
    const scale1 = useTransform(scrollY, [0, innerHeight ? innerHeight : 2000], [0.1, 1]);

    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: false,
    });

    const handleMute = () => {
        setVolume(volume ? 0 : 1);
    };

    return (
        <motion.div key="vimeo" variants={imageVariants}>
            <Wrapper style={{ scale: scale1 }}>
                <VideoContainer ref={ref}>
                    <MuteButton onClick={handleMute} style={{}}>
                        {volume ? (
                            <Sound width={30} height={30} fill={'#fff'} />
                        ) : (
                            <NoSound width={30} height={30} fill={'#fff'} />
                        )}
                    </MuteButton>
                    <VimeoLink href={VIMEO_PLAYER_URL + videoId} target={'_blank'} title={'Watch at Vimeo.com'}>
                        <VimeoLogo width={30} height={30} fill={'#fff'} />
                    </VimeoLink>
                    <div style={{ position: 'relative' }}>
                        <Vimeo
                            video={VIMEO_URL + videoId}
                            background={false}
                            loop={true}
                            autoplay={false}
                            controls={false}
                            responsive={true}
                            volume={volume}
                            paused={!inView}
                            transparent={true}
                        />
                        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }}></div>
                    </div>
                </VideoContainer>
            </Wrapper>
        </motion.div>
    );
};

const Wrapper = styled(motion.div)`
    padding: 1rem 0;
    z-index: 0;
    position: relative;
`;

const VideoContainer = styled.div`
    position: relative;
    width: 100%;
`;

const MuteButton = styled.button`
    position: absolute;
    z-index: 2;
    bottom: ${pxToRem(30)};
    left: ${pxToRem(15)};
    background: none;
    border: none;
    cursor: pointer;
`;

const VimeoLink = styled.a`
    position: absolute;
    bottom: ${pxToRem(30)};
    left: ${pxToRem(65)};
    z-index: 2;
`;
