import React, { FC, MouseEventHandler, useEffect, useState } from 'react';
import { ContentfulAsset } from '../../../model/generated/graphql';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { pxToRem } from '../../../utils/pxToRem';
import { imageVariants } from '../../../templates/projectDetails';
import { Container, ProjectSectionTitle } from './styles';

interface Props {
    thumbs: ContentfulAsset[];
    images: ContentfulAsset[];
}

export interface ThumbnailProps {
    data: ContentfulAsset;
    title?: string;
    handleClick?: MouseEventHandler;
}

interface GalleryImageProps {
    selectedFrame: string;
    data: ContentfulAsset;
    close: () => void;
}

export const Gallery: FC<Props> = ({ thumbs, images }) => {
    const [showLightbox, setShowLightbox] = useState(false);

    const [selectedFrame, setSelectedFrame] = useState(undefined);
    const [frames, setFrames] = useState(undefined);

    const handleFrameSelection = (id) => {
        setShowLightbox(!showLightbox);
        setSelectedFrame(id + 1);
    };

    useEffect(() => {
        if (selectedFrame) {
            const startFrames = images.slice(selectedFrame - 1);
            const endFrames = images.slice(0, selectedFrame - 1);
            const Frames = startFrames.concat(endFrames);
            setFrames(Frames);
        }
    }, [images, selectedFrame]);
    return (
        <Container maxWidth={660}>
            <motion.div key="thumbnails" variants={imageVariants}>
                <ProjectSectionTitle>Frames</ProjectSectionTitle>
                <GalleryGrid>
                    {thumbs.map((item, index) => (
                        <Thumbnail key={item.id} data={item} handleClick={() => handleFrameSelection(index)} />
                    ))}
                </GalleryGrid>
            </motion.div>
            <AnimatePresence>
                {showLightbox && (
                    <GalleryLightbox close={() => setShowLightbox(false)}>
                        {frames &&
                            frames.map((item) => {
                                return (
                                    <GalleryImage
                                        close={() => setShowLightbox(false)}
                                        selectedFrame={selectedFrame}
                                        data={item}
                                        key={`${item.id}`}
                                    />
                                );
                            })}
                    </GalleryLightbox>
                )}
            </AnimatePresence>
        </Container>
    );
};

export const GalleryLightbox = ({ children, close }) => {
    return (
        <motion.div>
            <GallerySlideshowWrapper>
                <motion.div
                    animate={{ y: 0 }}
                    style={{ y: 1400 }}
                    exit={{ y: 1400, opacity: 0 }}
                    transition={{ duration: 0.5, ease: [0.23, 1, 0.32, 1] }}
                >
                    <GallerySlideshowContainer>{children}</GallerySlideshowContainer>
                </motion.div>
            </GallerySlideshowWrapper>
        </motion.div>
    );
};

export const Thumbnail: FC<ThumbnailProps> = ({ data, handleClick }) => {
    const image = getImage(data.gatsbyImageData);
    return (
        <ThumbnailWrapper>
            <motion.div onClick={handleClick} whileHover={{ scale: 1.1 }}>
                <GatsbyImage alt="" image={image} objectFit={'cover'} />
            </motion.div>
        </ThumbnailWrapper>
    );
};

export const GalleryImage: FC<GalleryImageProps> = ({ data, close }) => {
    const image = getImage(data.gatsbyImageData);
    return (
        <GalleryImageContainer onClick={close}>
            <StyledGatsbyImage image={image} alt="" style={{ width: '100%', height: 'auto' }} loading="eager" />
        </GalleryImageContainer>
    );
};

const ThumbnailWrapper = styled.div`
    overflow: hidden;
    cursor: pointer;
    @media (max-width: 480px) {
        width: 150px;
        height: 150px;
    }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
    width: 100%;
    height: auto;
    @media (max-width: 860px) {
        width: 600px;
        height: auto;
    }
`;

const GalleryImageContainer = styled.div`
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.94);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${pxToRem(50)} ${pxToRem(150)};
    box-sizing: border-box;
    @media (max-width: 860px) {
        height: 100%;
        padding: 30px;
    }
`;

const GallerySlideshowWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;
    height: 100%;
`;

const GallerySlideshowContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    flex-wrap: wrap;
    @media (max-width: 860px) {
        width: auto;
        overflow-x: scroll;
    }
`;

const GalleryGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: ${pxToRem(30)} ${pxToRem(30)};
    @media (max-width: 600px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        gap: ${pxToRem(30)} ${pxToRem(0)};
    }
`;
