import React, { FC } from 'react';
import { ContentfulMovieInfo } from '../../../model/generated/graphql';
import { PageTitle } from '../../../pages/crew';
import styled from 'styled-components';
import { imageVariants } from '../../../templates/projectDetails';
import { motion } from 'framer-motion';
import { pxToRem } from '../../../utils/pxToRem';
import { Container, ProjectSectionTitle } from './styles';

interface Props {
    data: ContentfulMovieInfo;
}
export const Info: FC<Props> = ({ data }) => {
    const {
        director,
        scriptwriter,
        artisticProject,
        animations,
        cinematography,
        music,
        editing,
        techique,
        production,
        coProducers,
        coFinancing,
        releaseDate,
    } = data;
    return (
        <motion.div key="crew" variants={imageVariants}>
            <Container maxWidth={1440}>
                <Section>
                    <ProjectSectionTitle>Crew</ProjectSectionTitle>

                    <DefinitionList>
                        {director && (
                            <>
                                <dt>Directed by</dt>
                                <dd>{director}</dd>
                            </>
                        )}

                        {scriptwriter && (
                            <>
                                <dt>Written by</dt>
                                <dd>{scriptwriter}</dd>
                            </>
                        )}

                        {artisticProject && (
                            <>
                                <dt>Artistic Project</dt>
                                <dd>{artisticProject}</dd>
                            </>
                        )}

                        {animations && (
                            <>
                                <dt>Animations</dt>
                                <dd>{animations}</dd>
                            </>
                        )}

                        {cinematography && (
                            <>
                                <dt>Cinematography</dt>
                                <dd>{cinematography}</dd>
                            </>
                        )}

                        {music && (
                            <>
                                <dt>Music by</dt>
                                <dd>{music}</dd>
                            </>
                        )}
                        {editing && (
                            <>
                                <dt>Edited by</dt>
                                <dd>{editing}</dd>
                            </>
                        )}

                        {techique && (
                            <>
                                <dt>Technique</dt>
                                <dd>{techique}</dd>
                            </>
                        )}

                        {production && (
                            <>
                                <dt>Production</dt>
                                <dd>{production}</dd>
                            </>
                        )}

                        {coProducers && (
                            <>
                                <dt>Co-production</dt>
                                <dd>{coProducers}</dd>
                            </>
                        )}

                        {coFinancing && (
                            <>
                                <dt>Co-finansing</dt>
                                <dd>{coFinancing}</dd>
                            </>
                        )}

                        {releaseDate && (
                            <>
                                <dt>Release date</dt>
                                <dd>{releaseDate}</dd>
                            </>
                        )}
                    </DefinitionList>
                </Section>
            </Container>
        </motion.div>
    );
};

const Section = styled.div`
    text-align: center;

    ${ProjectSectionTitle} {
        margin-top: 5rem;
        margin-bottom: 3rem;
    }
`;

const DefinitionList = styled.dl`
    dt {
        font-size: 2rem;
        margin: 6rem 1rem 1rem;
        @media (max-width: ${pxToRem(800)}) {
            font-size: 1.5rem;
            margin: 3rem 1rem 1rem;
        }
    }
    dd {
        font-size: 1.5rem;
        margin: 0;
        @media (max-width: ${pxToRem(800)}) {
            font-size: 1rem;
        }
    }
`;
